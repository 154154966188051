import React, { memo } from 'react';
import { Modal } from 'reactstrap';

import style from '../../styles/layout.module.css';

const FullScreenImageModal = ({ isOpen, source, toggle }) => {
  return (
    <Modal
      className={style['warranty-full-screen-img']}
      zIndex={2000}
      centered
      isOpen={isOpen}
      toggle={toggle}
      fullscreen
    >
      <img src={source} onClick={toggle} style={{height:'100%' , objectFit:'contain'}}/>
    </Modal>
  );
};

export default memo(FullScreenImageModal);

import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import BankListOptions from 'data/bank.json';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';
import MessageUtil from 'utils/MessageUtil';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({
                     onOrderCancelInfo,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const dispatch = useDispatch();
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );
  const [editInfo, setEditInfo] = useState({});
  const [editInfoLoading, setEditInfoLoading] = useState(true);
  const handleGetEditInfo = async masterId => {
    setEditInfoLoading(true);
    const { code, result, message } = await request({
      dispatch,
      method: 'get',
      url: `/Backend/Order/OrderCancelEdit?masterId=${masterId}`,
    });
    setEditInfoLoading(false);
    if (code !== 200) {
      MessageUtil.alertWanring(message);
      return;
    }
    setEditInfo(result);
  };
  useEffect(() => {
    !loading &&
    orderListInfo &&
    orderListInfo?.datas?.[0]?.id &&
    handleGetEditInfo(orderListInfo?.datas?.[0]?.id);
  }, [loading, orderListInfo]);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      atmAccBank: editInfo.atmAccBank || '004',
      atmAccNo: editInfo.atmAccNo || '',
      bankAccountName: editInfo.bankAccountName || '',
      bankBranch: editInfo.bankBranch || '',
      orderMasterId: editInfo.orderMasterId,
      remark: editInfo.remark || '',
    },
    async onSubmit(formModel) {
      setEditInfoLoading(true);
      const { code, message } = await request({
        data: formModel,
        dispatch,
        method: 'post',
        url: `/Backend/Order/OrderCancel_v2?masterId=${formModel.orderMasterId}`,
      });
      setEditInfoLoading(false);
      if (code !== 200) {
        MessageUtil.alertWanring(message);
        return;
      }
      MessageUtil.toastSuccess('取消訂單成功!');
      onOrderCancelInfo && onOrderCancelInfo();
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      ...(editInfo.payStatus === 2 &&
        editInfo.returnWay === 'ATM轉帳退款' && {
          atmAccNo: Yup.string().required('必填'),
          bankAccountName: Yup.string().required('必填'),
          bankBranch: Yup.string().required('必填'),
        }),
      remark: Yup.string().required('必填'),
    }),
  });
  return (
    <Card className={style.card}>
      {!(
        orderListInfo?.datas?.[0]?.orderStatus !== 3 &&
        (orderListInfo?.datas?.[0]?.orderItems?.[0]?.shippingStatus === 0 ||
          orderListInfo?.datas?.[0]?.orderItems?.[0]?.shippingStatus === 1)
      ) && <p className="text-danger fw-bold">無法取消訂單</p>}

      <div className="table-responsive mb-5">
        <Table hover bordered striped>
          <thead
            className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
          >
          <tr>
            <th>
              <div>訂單編號</div>
              <div>訂單狀態</div>
            </th>
            <th>配送方式</th>
            <th>
              <div>轉單時間</div>
              <div>物流方式</div>
            </th>
            <th>
              <div>付款方式</div>
              <div>繳費期限</div>
              <div>預計出貨日</div>
            </th>
            <th>
              <div>商品名稱</div>
              <div>商品選項</div>
              <div>商品料號</div>
              <div>商品屬性</div>
            </th>
            <th>
              <div>數量</div>
              <div>商品單價</div>
              <div>總折扣金額</div>
              <div>總銷售金額(折扣後)</div>
            </th>
            <th>
              <div>有無商品贈品</div>
              <div>有無活動贈品</div>
              <div>折扣活動總折扣金額</div>
              <div>折價券總折扣金額</div>
              <div>贈品券活動序號</div>
            </th>
            <th>
              <div>會員編號</div>
              <div>會員等級</div>
              <div>收件人</div>
              <div>收件人電話</div>
            </th>
          </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
            {orderListInfo?.datas?.length > 0 ? (
              orderListInfo?.datas?.map((i, index) => (
                <React.Fragment key={i.id}>
                  <tr
                    className="text-nowrap"
                    style={{ backgroundColor: '#BEC0D8' }}
                  >
                    <td className="price text-black">
                      總金額：{(i.totalAmount || '0').toLocaleString('en-US')}
                    </td>
                    <td colSpan="2">主單編號：{i.orderNumber}</td>
                    <td colSpan="2">
                      <div>
                        運費：
                        <span className="price text-black">
                            {i.deliveryCharge?.toLocaleString('en-US')}
                          </span>
                        {i.deliveryChargeReturnNumber && (
                          <span>
                              （退運費申請 -
                              <span className={style.text_dark_info}>
                                {i.deliveryChargeReturnNumber}
                              </span>
                              )
                            </span>
                        )}
                      </div>
                    </td>
                    <td colSpan={3}>
                      <div className="d-flex align-items-center justify-content-end">
                        <FaChevronDown id={`orderId${i.id}`} />
                      </div>
                    </td>
                  </tr>
                  {i.orderItems.map((j, jIndex) => (
                    <tr key={jIndex}>
                      <td>
                        <div className="text-nowrap">{j.orderItemNumber}</div>
                        <div>{j.orderItemStatusName}</div>
                      </td>
                      <td>
                        <div>{j.logisticsWayName}</div>
                      </td>
                      <td>
                        <div>{i.orderTime}</div>
                        <div>{j.homeDeliveryCName || '--'}</div>
                      </td>
                      <td>
                        <div>{j.returnWay || '--'}</div>
                        <div>{j.expireDate_ATM || '--'}</div>
                        <div>{j.expectedDeliveryDate || '--'}</div>
                      </td>
                      <td>
                        {/* 商品名稱 */}
                        <div>{j.productname || '--'}</div>
                        {/* 商品選項 */}
                        <div>
                          {j.color || '--'}/{j.size || '--'}
                        </div>
                        {/* 商品料號 */}
                        <div
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                          onClick={() => {
                            if (Number(j.productType) === 4) {
                              window.open(
                                `/#/GiftManagement?page=1&keyword=${
                                  j.productname || j.freebieCode
                                }`,
                                '_blank',
                              );
                            } else if (Number(j.productType) === 1) {
                              window.open(
                                `/#/ProductControl?page=1&keyword=${
                                  j.productname || j.productcode
                                }`,
                                '_blank',
                              );
                            }
                          }}
                        >
                          {Number(j.productType) === 4
                            ? j.freebieCode || '--'
                            : j.productcode || '--'}
                        </div>
                        {/* 商品屬性 */}
                        <div>
                          {Number(j.productType) === 4
                            ? j.freebieType || '--'
                            : '商品'}
                        </div>
                      </td>
                      <td className="text-end">
                        <div>{j.quantity}</div>
                        <div className="price text-black fw-normal">
                          {j.unitPrice?.toLocaleString('en-US')}
                        </div>
                        <div className="d-flex justify-content-end">
                          -
                          <div className="price text-black fw-normal discountAmount">
                            {(j.discountAmount || '0').toLocaleString(
                              'en-US',
                            )}
                          </div>
                        </div>
                        <div className="price text-black fw-normal discountPrice">
                          {(j.discountPrice || '0').toLocaleString('en-US')}
                        </div>
                      </td>
                      <td className="text-end">
                        {/* 有無商品贈品 */}
                        <div>--</div>
                        {/* 有無活動贈品 */}
                        <div>--</div>
                        {/* 折扣活動總折扣金額 */}
                        <div
                          className="d-flex justify-content-end"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCurrentDiscountInfo(j.id);
                            setIsDiscountActivityModalOpen();
                          }}
                        >
                          -
                          <div
                            className={`price fw-normal ${style.text_dark_info}`}
                          >
                            {(
                              j.activityDiscountAmountUnit || '0'
                            )?.toLocaleString('en-US')}
                          </div>
                        </div>
                        {/* 折價券總折扣金額 */}
                        <div
                          className="d-flex justify-content-end"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCurrentDiscountInfo(j.id);
                            setIsDiscountModalOpen();
                          }}
                        >
                          -
                          <div
                            className={`price fw-normal ${style.text_dark_info}`}
                          >
                            {(
                              j.couponDiscountAmountUnit || '0'
                            )?.toLocaleString('en-US')}
                          </div>
                        </div>
                        {/* 贈品券活動序號 */}
                        <div>--</div>
                      </td>
                      <td>
                        <div>{j.memberPhone || '--'}</div>
                        <div>{j.memberLevel || '--'}</div>
                        <div>{j.name || '--'}</div>
                        <div>{j.phone || '--'}</div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <TableHasNoData />
            )}
            {error && <TableHasNoData />}
            </tbody>
          )}
        </Table>
        {loading && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
      </div>
      {orderListInfo?.datas?.[0]?.orderStatus !== 3 &&
        (orderListInfo?.datas?.[0]?.orderItems?.[0]?.shippingStatus === 0 ||
          orderListInfo?.datas?.[0]?.orderItems?.[0]?.shippingStatus === 1) && (
          <Form>
            {editInfoLoading && <BlockSpinner />}
            <FormGroup row>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                取消原因
              </Label>
              <Col>
                <Input
                  type="textarea"
                  rows={5}
                  name="remark"
                  onChange={handleChange}
                  value={values.remark}
                />
                {errors.remark && touched.remark && (
                  <FormText color="danger" className="d-block">
                    {errors.remark}
                  </FormText>
                )}
              </Col>
            </FormGroup>
            {editInfo.payStatus === 2 && (
              <React.Fragment>
                <h4 className="title">退款資訊</h4>
                <FormGroup row>
                  <Label style={{ width: 150 }}>退款方式</Label>
                  <Col>{editInfo.returnWay}</Col>
                </FormGroup>
                {editInfo.returnWay === 'ATM轉帳退款' && (
                  <FormGroup className="d-flex">
                    <Label style={{ width: 150 }} className="required">
                      匯款帳號
                    </Label>
                    <Row>
                      <Col xs={12} md={4} className="mb-2">
                        <Input
                          type="select"
                          name="atmAccBank"
                          onChange={handleChange}
                          value={values.atmAccBank}
                        >
                          {Object.keys(BankListOptions)
                            .sort()
                            .map(i => (
                              <option value={i} key={i}>
                                {i} {BankListOptions[i].name}
                              </option>
                            ))}
                        </Input>
                      </Col>
                      <Col xs={12} md={4} className="mb-2">
                        <Input
                          type="text"
                          placeholder="請填寫分行名稱"
                          name="bankBranch"
                          onChange={handleChange}
                          value={values.bankBranch}
                        />
                        {errors.bankBranch && (
                          <FormText color="danger" className="d-block">
                            {errors.bankBranch}
                          </FormText>
                        )}
                      </Col>
                      <Col xs={12} md={4} className="mb-2">
                        <Input
                          type="text"
                          placeholder="請填寫戶名"
                          name="bankAccountName"
                          onChange={handleChange}
                          value={values.bankAccountName}
                        />
                        {errors.bankAccountName && (
                          <FormText color="danger" className="d-block">
                            {errors.bankAccountName}
                          </FormText>
                        )}
                      </Col>
                      <Col xs={12} className="mb-2">
                        <Input
                          type="text"
                          placeholder="請填寫完整帳號"
                          name="atmAccNo"
                          onChange={handleChange}
                          value={values.atmAccNo}
                        />
                        {errors.atmAccNo && (
                          <FormText color="danger" className="d-block">
                            {errors.atmAccNo}
                          </FormText>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                {editInfo.returnWay === '信用卡退款' && (
                  <FormGroup row>
                    <Label style={{ width: 150 }}>發卡銀行</Label>
                    <Col>{editInfo.bank}</Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Label style={{ width: 150 }}>退款金額</Label>
                  <Col className="price fw-normal text-dark">
                    {editInfo.actualAmount?.toLocaleString('en-US')}
                  </Col>
                </FormGroup>
              </React.Fragment>
            )}
            <FormGroup className="text-center mt-5">
              <Button
                color="primary"
                className={style.modal_button_submit}
                onClick={handleSubmit}
                disabled={editInfoLoading || Object.keys(errors).length > 0}
              >
                {editInfoLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                確認取消
              </Button>
            </FormGroup>
          </Form>
        )}
    </Card>
  );
};
const CancelOrderCreate = () => {
  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
    useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);
  const { values, handleChange, handleSubmit, handleReset, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        orderNumber: '',
        orderNumberType: '主單編號',
        page: 1,
        page_size: 10,
      },
      async onSubmit(formModel) {
        setShowTable(true);
        const params = {
          ...formModel,
          page: 1,
        };
        await dispatch(getOrderList(params));
      },
      validationSchema: Yup.object().shape({
        orderNumber: Yup.string().required('必填'),
      }),
    });

  return (
    <Layout
      pageTitle="新增取消單"
      items={[
        { isActive: false, page_name: '取消訂單管理' },
        { isActive: true, page_name: '新增取消單' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            只能查詢-出貨單狀態【待確認】【已確認待出貨】的訂單
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            取消訂單後，即會進入退款流程，取消前請務必確認。
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                  invalid={touched.orderNumber && errors.orderNumber}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                setShowTable(false);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      {showTable && (
        <TableList
          onOrderCancelInfo={() => {
            handleReset();
            setShowTable(false);
          }}
          setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
          setIsDiscountModalOpen={setIsDiscountModalOpen}
          setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
      )}
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={setIsDiscountModalOpen}
          id={currentDiscountInfo}
        />
      )}
      {isDiscountActivityModalOpen && (
        <DiscountActivityDetailModal
          isOpen={isDiscountActivityModalOpen}
          toggle={setIsDiscountActivityModalOpen}
          id={currentDiscountInfo}
        />
      )}
    </Layout>
  );
};
export default CancelOrderCreate;

import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  BRAND_PREFIX_LIST_FAIL,
  BRAND_PREFIX_LIST_REQUEST,
  BRAND_PREFIX_LIST_SUCCESS,
  PRODUCT_EXCEL_PRODUCE_FAIL,
  PRODUCT_EXCEL_PRODUCE_REQUEST,
  PRODUCT_EXCEL_PRODUCE_SUCCESS,
  PRODUCT_NUMBER_CREATE_FAIL,
  PRODUCT_NUMBER_CREATE_REQUEST,
  PRODUCT_NUMBER_CREATE_SUCCESS,
  PRODUCT_NUMBER_LIST_FAIL,
  PRODUCT_NUMBER_LIST_REQUEST,
  PRODUCT_NUMBER_LIST_SUCCESS,
  PRODUCT_SERIAL_NUMBER_FAIL,
  PRODUCT_SERIAL_NUMBER_REQUEST,
  PRODUCT_SERIAL_NUMBER_SUCCESS,
  SHIPPING_DEALER_LIST_FAIL,
  SHIPPING_DEALER_LIST_REQUEST,
  SHIPPING_DEALER_LIST_SUCCESS,
  SHIPPING_EXCEL_PRODUCE_FAIL,
  SHIPPING_EXCEL_PRODUCE_REQUEST,
  SHIPPING_EXCEL_PRODUCE_SUCCESS,
  WARRANTY_BRAND_EXPIRE_FAIL,
  WARRANTY_BRAND_EXPIRE_REQUEST,
  WARRANTY_BRAND_EXPIRE_SUCCESS,
  WARRANTY_BRAND_LIST_FAIL,
  WARRANTY_BRAND_LIST_REQUEST,
  WARRANTY_BRAND_LIST_SUCCESS,
  WARRANTY_BRAND_SET_FAIL,
  WARRANTY_BRAND_SET_REQUEST,
  WARRANTY_BRAND_SET_SUCCESS,
  WARRANTY_CODE_LIST_FAIL,
  WARRANTY_CODE_LIST_REQUEST,
  WARRANTY_CODE_LIST_SUCCESS,
  WARRANTY_CODE_POST_FAIL,
  WARRANTY_CODE_POST_REQUEST,
  WARRANTY_CODE_POST_SUCCESS,
  WARRANTY_EXCEL_PRODUCE_FAIL,
  WARRANTY_EXCEL_PRODUCE_REQUEST,
  WARRANTY_EXCEL_PRODUCE_SUCCESS,
  WARRANTY_INFO_GET_FAIL,
  WARRANTY_INFO_GET_REQUEST,
  WARRANTY_INFO_GET_SUCCESS,
  WARRANTY_INFO_UPDATE_FAIL,
  WARRANTY_INFO_UPDATE_REQUEST,
  WARRANTY_INFO_UPDATE_SUCCESS,
  WARRANTY_MASTER_LIST_FAIL,
  WARRANTY_MASTER_LIST_REQUEST,
  WARRANTY_MASTER_LIST_SUCCESS,
  WARRANTY_NUMBER_CREATE_FAIL,
  WARRANTY_NUMBER_CREATE_REQUEST,
  WARRANTY_NUMBER_CREATE_SUCCESS,
  WARRANTY_NUMBER_LIST_FAIL,
  WARRANTY_NUMBER_LIST_REQUEST,
  WARRANTY_NUMBER_LIST_SUCCESS,
  WARRANTY_PRODUCT_CHECK_FAIL,
  WARRANTY_PRODUCT_CHECK_REQUEST,
  WARRANTY_PRODUCT_CHECK_SUCCESS,
  WARRANTY_REGISTER_DETAILS_FAIL,
  WARRANTY_REGISTER_DETAILS_REQUEST,
  WARRANTY_REGISTER_DETAILS_SUCCESS,
  WARRANTY_REGISTER_EXPORT_FAIL,
  WARRANTY_REGISTER_EXPORT_REQUEST,
  WARRANTY_REGISTER_EXPORT_SUCCESS,
  WARRANTY_REGISTER_LIST_FAIL,
  WARRANTY_REGISTER_LIST_REQUEST,
  WARRANTY_REGISTER_LIST_SUCCESS,
  WARRANTY_REGISTER_NUMBER_FAIL,
  WARRANTY_REGISTER_NUMBER_REQUEST,
  WARRANTY_REGISTER_NUMBER_SUCCESS,
  WARRANTY_REGISTER_RECORD_FAIL,
  WARRANTY_REGISTER_RECORD_REQUEST,
  WARRANTY_REGISTER_RECORD_SUCCESS,
  WARRANTY_REGISTER_REVIEW_FAIL,
  WARRANTY_REGISTER_REVIEW_REQUEST,
  WARRANTY_REGISTER_REVIEW_SUCCESS,
  WARRANTY_REMARK_ADD_FAIL,
  WARRANTY_REMARK_ADD_REQUEST,
  WARRANTY_REMARK_ADD_SUCCESS,
  WARRANTY_SERIAL_NUMBER_FAIL,
  WARRANTY_SERIAL_NUMBER_REQUEST,
  WARRANTY_SERIAL_NUMBER_SUCCESS,
  GET_SIZE_OPTIONS_REQUEST,
  GET_SIZE_OPTIONS_SUCCESS,
  GET_SIZE_OPTIONS_FAIL,
  GET_FORM_DESCRIPTION_FAIL,
  GET_FORM_DESCRIPTION_REQUEST,
  GET_FORM_DESCRIPTION_SUCCESS,
  UPDATE_FORM_DESCRIPTION_FAIL,
  UPDATE_FORM_DESCRIPTION_REQUEST,
  UPDATE_FORM_DESCRIPTION_SUCCESS,
  GET_CAN_UNBIND_SHIPPER_LIST_FAIL,
  GET_CAN_UNBIND_SHIPPER_LIST_REQUEST,
  GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS,
  GET_WARRANTY_NOTIFY_LIST_FAIL,
  GET_WARRANTY_NOTIFY_LIST_SUCCESS,
  GET_WARRANTY_NOTIFY_LIST_REQUEST,
  UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
  UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
  UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
} from '../../constants/data/warrantyConstants';

import { baseURL, prefix_api_path } from './api_config';

//get warranty code list
export const getWarrantyCodeList = ({ page, keyword, status }) => {
  const url = `/Backend/BrandSerialNumPrefix/list?page=${page}&page_size=10${
    keyword ? `&keyword=${keyword}` : ''
  }${status ? `&status=${status}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: WARRANTY_CODE_LIST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_CODE_LIST_REQUEST,
      successConstant: WARRANTY_CODE_LIST_SUCCESS,
      url,
    });
  };
};

//post warranty code
export const postWarrantyCode = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/BrandSerialNumPrefix/brandSerialNumPrefix',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: WARRANTY_CODE_POST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_CODE_POST_REQUEST,
      successConstant: WARRANTY_CODE_POST_SUCCESS,
      url: '/Backend/BrandSerialNumPrefix/brandSerialNumPrefix',
    });
  };
};

//post to get warranty number list
export const getWarrantyNumberList = info => {
  CommonUtil.consoleLog({ anno: 'request body', data: info });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WarrantySerialNumMt/list',
  });
  return async dispatch => {
    await useRequest({
      data: info,
      dispatch,
      failConstant: WARRANTY_NUMBER_LIST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_NUMBER_LIST_REQUEST,
      successConstant: WARRANTY_NUMBER_LIST_SUCCESS,
      url: '/Backend/WarrantySerialNumMt/list',
    });
  };
};

//post to get warranty serial numbers
export const getWarrantySerialNumbers = info => {
  CommonUtil.consoleLog({ anno: 'request body', data: info });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WarrantySerialNumMt/WarrantySerialNumEdit',
  });
  return async dispatch => {
    await useRequest({
      data: info,
      dispatch,
      failConstant: WARRANTY_SERIAL_NUMBER_FAIL,
      method: 'post',
      requestConstant: WARRANTY_SERIAL_NUMBER_REQUEST,
      successConstant: WARRANTY_SERIAL_NUMBER_SUCCESS,
      url: '/Backend/WarrantySerialNumMt/WarrantySerialNumEdit',
    });
  };
};

//get warranty prefix list
export const getWarrantyPrefixList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_PREFIX_LIST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${
          baseURL + prefix_api_path
        }/Backend/BrandSerialNumPrefix/BrandSerialNumPrefixSetList`,
        config,
      );

      dispatch({
        payload: data.result,
        type: BRAND_PREFIX_LIST_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: BRAND_PREFIX_LIST_FAIL,
      });
    }
  };
};

//create warranty serial number
export const createWarrantyNumber = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WarrantySerialNumMt/WarrantySerialNumCreate',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_NUMBER_CREATE_FAIL,
      method: 'post',
      requestConstant: WARRANTY_NUMBER_CREATE_REQUEST,
      successConstant: WARRANTY_NUMBER_CREATE_SUCCESS,
      url: '/Backend/WarrantySerialNumMt/WarrantySerialNumCreate',
    });
  };
};

//get warranty excel
export const getWarrantyExcel = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_EXCEL_PRODUCE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${
          baseURL + prefix_api_path
        }/Backend/WarrantySerialNumMt/WarrantySerialNumExport/${id}`,
        config,
      );

      dispatch({
        payload: data.result,
        type: WARRANTY_EXCEL_PRODUCE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_EXCEL_PRODUCE_FAIL,
      });
    }
  };
};

//post to get product number list
export const getProductNumberList = info => {
  CommonUtil.consoleLog({ anno: 'request body', data: info });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/ProductSerialNumMt/list',
  });
  return async dispatch => {
    await useRequest({
      data: info,
      dispatch,
      failConstant: PRODUCT_NUMBER_LIST_FAIL,
      method: 'post',
      requestConstant: PRODUCT_NUMBER_LIST_REQUEST,
      successConstant: PRODUCT_NUMBER_LIST_SUCCESS,
      url: '/Backend/ProductSerialNumMt/list',
    });
  };
};

//post to get product serial numbers
export const getProductSerialNumbers = info => {
  CommonUtil.consoleLog({ anno: 'request body', data: info });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/ProductSerialNumMt/ProductSerialNumEdit',
  });
  return async dispatch => {
    await useRequest({
      data: info,
      dispatch,
      failConstant: PRODUCT_SERIAL_NUMBER_FAIL,
      method: 'post',
      requestConstant: PRODUCT_SERIAL_NUMBER_REQUEST,
      successConstant: PRODUCT_SERIAL_NUMBER_SUCCESS,
      url: '/Backend/ProductSerialNumMt/ProductSerialNumEdit',
    });
  };
};

//create product serial number
export const createProductNumber = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/ProductSerialNumMt/ProductSerialNumCreate',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: PRODUCT_NUMBER_CREATE_FAIL,
      method: 'post',
      requestConstant: PRODUCT_NUMBER_CREATE_REQUEST,
      successConstant: PRODUCT_NUMBER_CREATE_SUCCESS,
      url: '/Backend/ProductSerialNumMt/ProductSerialNumCreate',
    });
  };
};

//get product excel
export const getProductExcel = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_EXCEL_PRODUCE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${
          baseURL + prefix_api_path
        }/Backend/ProductSerialNumMt/ProductSerialNumExport/${id}`,
        config,
      );

      dispatch({
        payload: data.result,
        type: PRODUCT_EXCEL_PRODUCE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: PRODUCT_EXCEL_PRODUCE_FAIL,
      });
    }
  };
};

//get shipping dealer list
export const getShippingDealerList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHIPPING_DEALER_LIST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/ShipperMt/options`,
        config,
      );

      dispatch({
        payload: data.result,
        type: SHIPPING_DEALER_LIST_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SHIPPING_DEALER_LIST_FAIL,
      });
    }
  };
};

//warranty and product number check
export const checkWarrantyAndProductNumber = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_PRODUCT_CHECK_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/ShipperMt/shipperChkSingle`,
        info,
        config,
      );

      dispatch({
        payload: data,
        type: WARRANTY_PRODUCT_CHECK_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_PRODUCT_CHECK_FAIL,
      });
    }
  };
};

//warranty and product number submit
export const submitWarrantyAndProductNumber = async data => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/ShipperMt/shipperMt',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

//post to get warranty master list
export const getWarrantyMasterList = info => {
  CommonUtil.consoleLog({ anno: 'request body', data: info });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/ShipperMt/list' });
  return async dispatch => {
    await useRequest({
      data: info,
      dispatch,
      failConstant: WARRANTY_MASTER_LIST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_MASTER_LIST_REQUEST,
      successConstant: WARRANTY_MASTER_LIST_SUCCESS,
      url: '/Backend/ShipperMt/list',
    });
  };
};

//get warranty info
export const getWarrantyinfo = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_INFO_GET_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/ShipperMt/ShiiperBindEdit/${id}`,
        config,
      );

      dispatch({
        payload: data.result,
        type: WARRANTY_INFO_GET_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_INFO_GET_FAIL,
      });
    }
  };
};

//update warranty info
export const updateWarrantyInfo = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/ShipperMt/ShiiperBindReBind',
  });

  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_INFO_UPDATE_FAIL,
      method: 'post',
      requestConstant: WARRANTY_INFO_UPDATE_REQUEST,
      successConstant: WARRANTY_INFO_UPDATE_SUCCESS,
      url: '/Backend/ShipperMt/ShiiperBindReBind',
    });
  };
};

export const deleteWarrantyInfo = (object = []) => {
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_INFO_UPDATE_FAIL,
      method: 'post',
      requestConstant: WARRANTY_INFO_UPDATE_REQUEST,
      successConstant: WARRANTY_INFO_UPDATE_SUCCESS,
      url: '/Backend/ShipperMt/deleteShipperDt',
    });
  };
};
//get shipping excel
export const getShippingExcel = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SHIPPING_EXCEL_PRODUCE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/ShipperMt/ShipperExport`,
        object,
        config,
      );

      dispatch({
        payload: data.result,
        type: SHIPPING_EXCEL_PRODUCE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SHIPPING_EXCEL_PRODUCE_FAIL,
      });
    }
  };
};

//get warranty brand list
export const getWarrantyBrandList = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/BrandWarrantyDateSetting/list',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_BRAND_LIST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_BRAND_LIST_REQUEST,
      successConstant: WARRANTY_BRAND_LIST_SUCCESS,
      url: '/Backend/BrandWarrantyDateSetting/list',
    });
  };
};

//get warranty brand expire date
export const getWarrantyBrandExpireDate = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_BRAND_EXPIRE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${
          baseURL + prefix_api_path
        }/Backend/BrandWarrantyDateSetting/brandWarrantyDateSetting/${id}`,
        config,
      );

      dispatch({
        payload: data,
        type: WARRANTY_BRAND_EXPIRE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_BRAND_EXPIRE_FAIL,
      });
    }
  };
};

//update warranty brand
export const updateWarrantyBrand = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/BrandWarrantyDateSetting/brandWarrantyDateSetting',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_BRAND_SET_FAIL,
      method: 'post',
      requestConstant: WARRANTY_BRAND_SET_REQUEST,
      successConstant: WARRANTY_BRAND_SET_SUCCESS,
      url: '/Backend/BrandWarrantyDateSetting/brandWarrantyDateSetting',
    });
  };
};

//get warranty register list
export const getWarrantyRegisterList = object => {

  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_REGISTER_LIST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_REGISTER_LIST_REQUEST,
      successConstant: WARRANTY_REGISTER_LIST_SUCCESS,
      url: '/Backend/WarrantyRegister/list',
    });
  };
};

export const getWarrantyRegisterDetails = id => {

  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: WARRANTY_REGISTER_DETAILS_FAIL,
      method: 'get',
      requestConstant: WARRANTY_REGISTER_DETAILS_REQUEST,
      successConstant: WARRANTY_REGISTER_DETAILS_SUCCESS,
      url: `/Backend/WarrantyRegister/Get?id=${id}`,
    });
  };
};

//add warranty remark
export const addWarrantyRemark = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_REMARK_ADD_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/WarrantyRegister/AddRemark`,
        object,
        config,
      );

      dispatch({
        payload: data,
        type: WARRANTY_REMARK_ADD_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_REMARK_ADD_FAIL,
      });
    }
  };
};

//review warranty register
export const reviewWarrantyRegister = object => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WarrantyRegister/Review',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: WARRANTY_REGISTER_REVIEW_FAIL,
      method: 'post',
      requestConstant: WARRANTY_REGISTER_REVIEW_REQUEST,
      successConstant: WARRANTY_REGISTER_REVIEW_SUCCESS,
      url: '/Backend/WarrantyRegister/Review',
    });
  };
};

//get warranty register number
export const getWarrantyRegisterNumber = () => {
  const url = '/Backend/WarrantyRegister/GetWarrantyNum';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: WARRANTY_REGISTER_NUMBER_FAIL,
      method: 'get',
      requestConstant: WARRANTY_REGISTER_NUMBER_REQUEST,
      successConstant: WARRANTY_REGISTER_NUMBER_SUCCESS,
      url,
    });
  };
};

//get warranty register record
export const getWarrantyRecord = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_REGISTER_RECORD_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/WarrantyRegister/GetRecord/${id}`,
        config,
      );

      dispatch({
        payload: data.result,
        type: WARRANTY_REGISTER_RECORD_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_REGISTER_RECORD_FAIL,
      });
    }
  };
};

//export warranty register
export const exportWarrantyRegister = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_REGISTER_EXPORT_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/WarrantyRegister/Export`,
        object,
        config,
      );

      dispatch({
        payload: data.result,
        type: WARRANTY_REGISTER_EXPORT_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: WARRANTY_REGISTER_EXPORT_FAIL,
      });
    }
  };
};

export const checkWarrantyNumber = async object => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.get(
      `${
        baseURL + prefix_api_path
      }/Backend/ShipperMt/ShippingCellChk/warranty/${object.serialNumber}/${
        object.type
      }`,
      config,
    );
    return data.result;
  } catch (e) {}
};

export const checkBarcodeNumber = async object => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `${baseURL + prefix_api_path}/Backend/ShipperMt/ShippingCellChk/barcode/${
        object.serialNumber
      }/${object.type}`,
      config,
    );

    return data.result;
  } catch (e) {}
};

export const checkProductNumber = async object => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `${
        baseURL + prefix_api_path
      }/Backend/ShipperMt/ShippingCellChk/productSerial/${
        object.serialNumber
      }/${object.type}`,
      config,
    );
    return data.result;
  } catch (e) {}
};

export const getProductName = async info => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.post(
      `${baseURL + prefix_api_path}/Backend/ShipperMt/shipperChkSingle`,

      // `${baseURL + prefix_api_path}/Backend/ShipperMt/product`,
      info,
      config,
    );

    return data;
  } catch (e) {}
};

export const getSizeOptions = params => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_SIZE_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_SIZE_OPTIONS_REQUEST,
      successConstant: GET_SIZE_OPTIONS_SUCCESS,
      url: `/Website/WarrantyRegister/sizeoptions/0/0?modelId=${params.modelId || ''}&color=${params.color || ''}`,
    });
  };
};

export const createShipperMt = async (
  suppliercode,
  shippingCount,
  remark = '',
  codeType,
) => {
  return await request({
    data: {
      codeType,
      id: 0,
      remark,
      shippingCount,
      suppliercode,
    },
    method: 'post',
    url: '/Backend/ShipperMt/createShipperMt',
  }).then(res => {
    return res;
  });
};

export const shipperSingelCheck = async (
  data = {
    barcode: '',
    codeType: 1,
    serialnumber: '',
    shipperDtId: 0,
    shipperMtId: 0,
    suppliercode: '',
    warrantySerialNum: '',
    warrantySerialNumDtId: '',
  },
) => {
  return await request({
    data,
    method: 'post',
    url: `/Backend/ShipperMt/shipperSingelCheck/${data.codeType}`,
  }).then(res => {
    if (
      !res.message &&
      !res.serialnumberResult &&
      !res.warrantySerialNumResult &&
      !res.barcodeResult
    ) {
      return res;
    } else {
      throw res;
    }
  });
};

export const deleteShipperDt = async (
  data = [
    {
      shipperDtId: 1074,
    },
  ],
) => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/ShipperMt/deleteShipperDt',
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};
export const UpdateShipperDt = async (
  data = {
    barcode: '',
    id: 0,
    serialnumber: '',
    shipperDtId: 0,
    warrantySerialNum: '',
  },
) => {
  return await request({
    data,
    method: 'post',
    url: `Backend/ShipperMt/UpdateShipperDt/${data.shipperDtId}`,
  }).then(res => {
    if (
      !res.message &&
      !res.serialnumberResult &&
      !res.warrantySerialNumResult &&
      !res.barcodeResult
    ) {
      return res;
    } else {
      throw res;
    }
  });
};

export const GetFormDescription = () => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_FORM_DESCRIPTION_FAIL,
      method: 'get',
      requestConstant: GET_FORM_DESCRIPTION_REQUEST,
      successConstant: GET_FORM_DESCRIPTION_SUCCESS,
      url: '/Backend/WarrantyRegister/GetFormDescription',
    });
  };
};

export const UpdateFormDescription = (
  data = {
    new_Content: '',
    new_Content_en: '',
    old_Content: '',
    old_Content_en: '',
  },
) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_FORM_DESCRIPTION_FAIL,
      method: 'post',
      requestConstant: UPDATE_FORM_DESCRIPTION_REQUEST,
      successConstant: UPDATE_FORM_DESCRIPTION_SUCCESS,
      url: '/Backend/WarrantyRegister/UpdateFormDescription',
    });
  };
};

export const BatchReview = async (data = []) => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/WarrantyRegister/BatchReview',
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};

export const GetCanUnbindShipperDtList = (
  data = {
    currentPage: 0,
    pageSize: 0,
  },
) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_CAN_UNBIND_SHIPPER_LIST_FAIL,
      method: 'post',
      requestConstant: GET_CAN_UNBIND_SHIPPER_LIST_REQUEST,
      successConstant: GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS,
      url: '/Backend/ShipperMt/GetCanUnbindShipperDtList',
    });
  };
};

export const UnbindShipperDt = async (
  data = {
    reason: '',
    shipperDtIds: [],
  },
) => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/ShipperMt/UnbindShipperDt',
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};

export const CheckSerialnumberCanBatchUnbind = async (serialnumber = '') => {
  return await request({
    method: 'get',
    params: {
      serialnumber,
    },
    url: `/Backend/ShipperMt/CheckSerialnumberCanBatchUnbind/${serialnumber}`,
  }).then(res => {
    if (String(res.code) === '200') {
      return res;
    } else {
      throw res;
    }
  });
};


export const getWarrantyRegisterNotifyList = () => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: GET_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: GET_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/WarrantyRegister/WarrantyRegisterNotifyList',
    });
  };
};

export const createWarrantyRegisterNotifyList = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:19:52.506Z',
  'startTime': '2024-09-18T17:19:52.506Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/WarrantyRegister/CreateWarrantyRegisterNotify',
    });
  };
};

export const updateWarrantyRegisterNotifyList = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:20:27.701Z',
  'id': 0,
  'startTime': '2024-09-18T17:20:27.701Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/WarrantyRegister/UpdateWarrantyRegisterNotify',
    });
  };
};

export const deleteWarrantyRegisterNotifyList = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:20:27.701Z',
  'id': 0,
  'startTime': '2024-09-18T17:20:27.701Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      params:data,
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/WarrantyRegister/DeleteWarrantyRegisterNotify',
    });
  };
};


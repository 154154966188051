import moment from 'moment/moment';
import React, { memo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from 'reactstrap';

import { getOrderOptions } from '../../redux/actions/data/orderActions';
import style from '../../styles/layout.module.css';
import { useStore } from '../../utils/useStore';
import SearchSelect from '../OrderSearchSelector';

const SearchForm = ({
  handleSubmit,
  handleReset,
  setFieldValue,
  values,
  enteredValueCheckingHandler,
}) => {
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);
  const dispatch = useDispatch();
  const { options: StoreOptions } = useStore();

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);

  return (
    <Card className={style.search_card} style={{ zIndex: 100 }}>
      <CardHeader className={style.card_header}>搜尋條件</CardHeader>
      <CardBody className={style.card_body}>
        <form>
          <div className={style.show_flex}>
            <div
              className={style.formGroup}
              style={{ marginRight: '1.5rem', width: '100%' }}
            >
              <label className="col-form-label text-nowrap mr-2">關鍵字</label>
              <input
                type="text"
                className={`form-control ${style['form-keyword']}`}
                name="keyword"
                placeholder="會員電話、收件人、收件人電話、商品名稱、品項編碼"
                onChange={e => {
                  setFieldValue('keyword', e.target.value);
                }}
                value={values.keyword}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">付款方式</label>
              <SearchSelect
                className={style['form-select']}
                options={orderOptionsInfo?.payWay}
                emptyVal={0}
                name="payWay"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">配送方式</label>
              <SearchSelect
                className={style['form-select-medium']}
                options={orderOptionsInfo?.logisticsWay}
                emptyVal={0}
                name="logisticsWay"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">商品交期</label>
              <SearchSelect
                className={style['form-select-short']}
                options={orderOptionsInfo?.deliveryType}
                emptyVal={0}
                name="deliveryType"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">主單狀態</label>
              <SearchSelect
                className={style['form-select-medium']}
                options={orderOptionsInfo?.orderStatus}
                emptyVal={0}
                name="orderStatus"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">出貨單狀態</label>
              <SearchSelect
                className={style['form-select-short']}
                options={orderOptionsInfo?.shippingStatus}
                emptyVal=""
                name="shippingStatus"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">物流狀態</label>
              <SearchSelect
                className={style['form-select']}
                options={orderOptionsInfo?.logisticsStatus}
                emptyVal=""
                name="logisticsStatus"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2 text-nowrap">編號</label>
              <Input
                type="select"
                className={style['form-select-medium']}
                name="orderNumberType"
                onChange={e => {
                  setFieldValue('orderNumberType', e.target.value);
                }}
                value={values.orderNumberType}
                style={{ margin: '0' }}
              >
                <option value="主單編號">主單編號</option>
                <option value="訂單編號">訂單編號</option>
                <option value="出貨單編號">出貨單編號</option>
                <option value="配送編號">配送編號</option>
              </Input>
              <Input
                type="text"
                className={style['form-input-order-number']}
                name="orderNumber"
                onChange={e => {
                  setFieldValue('orderNumber', e.target.value);
                }}
                onInput={enteredValueCheckingHandler}
                onBlur={enteredValueCheckingHandler}
                value={values.orderNumber}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2 text-nowrap">
                日期區間
              </label>
              <Input
                type="select"
                className={style['form-select-medium']}
                name="timeType"
                onChange={e => {
                  setFieldValue('timeType', e.target.value);
                }}
                value={values.timeType}
                style={{ margin: '0' }}
              >
                <option value="訂購時間">訂購時間</option>
                <option value="預購出貨日">預購出貨日</option>
                <option value="訂單狀態日">訂單狀態日</option>
                <option value="出貨狀態日">出貨狀態日</option>
                <option value="轉單時間">轉單時間</option>
              </Input>
              <DatePicker
                className="form-control"
                popperClassName={style['form-control-date-picker']}
                dateFormat="yyyy/MM/dd p"
                showTimeSelect
                selected={
                  values.startTime
                    ? moment(values.startTime).toDate()
                    : moment().subtract(7, 'day').toDate()
                }
                name="startTime"
                onChange={date => {
                  setFieldValue('startTime', date);
                }}
              />
              <span style={{ margin: '1rem' }}>~</span>
              <DatePicker
                className="form-control"
                popperClassName={style['form-control-date-picker']}
                dateFormat="yyyy/MM/dd p"
                showTimeSelect
                selected={
                  values.endTime
                    ? moment(values.endTime).toDate()
                    : moment().toDate()
                }
                name="endTime"
                onChange={date => {
                  setFieldValue('endTime', date);
                }}
                minDate={values.startTime}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">門市</label>
              <Select
                className="w-100"
                options={[{label:'全部',value: null} , ...StoreOptions ]}
                value={
                  StoreOptions.find(
                    o => String(o.value) === String(values.salesChannel),
                  ) || {
                    label: '全部',
                    value: null,
                  }
                }
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    minWidth:'200px',
                    whiteSpace: 'nowrap',
                  }),
                  menuList: (provided, state) => ({
                    ...provided,
                    whiteSpace: 'nowrap',
                  }),
                }}
                onChange={e => {
                  setFieldValue('salesChannel', e.value);
                }}
              />
            </div>
          </div>
        </form>
      </CardBody>
      <CardFooter className="text-end">
        <Button
          type="button"
          className={style.modal_button_cancel}
          onClick={() => {
            handleReset();
            handleSubmit();
          }}
        >
          取消
        </Button>
        <Button
          type="button"
          className={style.modal_button_submit}
          onClick={() => {
            setFieldValue('page', 1);
            handleSubmit();
          }}
        >
          查詢
        </Button>
      </CardFooter>
    </Card>
  );
};

export default memo(SearchForm);

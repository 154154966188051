import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Input } from 'reactstrap';
import {
  getAreaList,
  getCityList,
  getCountryList,
} from 'redux/actions/data/locationActions';
import { getSizeOptions } from 'redux/actions/data/warrantyActions';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import {
  addRepairRequest,
  getColorModelOption, getProductInfoViaWarrantySerialNumber,
  getRepairBrandOption,
  getRepairModelOption,
  getRepairOptions,
} from '../redux/actions/data/repairActions';
import {
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE,
  MAINTAIN_ADD_RESET,
  MAINTAIN_GET_INFO_RESET,
} from '../redux/constants/data/repairConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const MaintainanceAdd = () => {
  const dispatch = useDispatch();
  const { countryList } = useSelector(state => state.getCountryList);
  const { cityList } = useSelector(state => state.getCityList);
  const { areaList } = useSelector(state => state.getAreaList);

  const countryOptions = countryList.map(country => {
    return { label: country.name, value: country.id };
  });
  const cityListOptions = cityList.map(city => {
    return { label: city.name, value: city.id };
  });
  const areaListOptions = areaList.map(area => {
    return { label: area.name, value: area.id };
  });

  const { values , handleReset, handleSubmit, setFieldValue, errors, touched, isSubmitting } =
      useFormik({
        enableReinitialize: true,
        initialValues: {
          brand: { label: '請選擇', value: 0 },
          channel: { label: '請選擇', value: '' },
          color: { label: '請選擇', value: '' },
          colorOptions: [],
          date: '',
          model: { label: '請選擇', value: '' },
          otherRepiarItem: '',
          otherRepiarItemInputValue: '',
          problem: '',
          productSerailNum: '',
          receiver: {
            address: '',
            city: { label: '請選擇', value: 0 },
            country: { label: '請選擇', value: 0 },
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            town: { label: '請選擇', value: 0 },
          },
          receiverSupplier: { label: '請選擇', value: '' },
          receiverType: '經銷商',
          repairItems: ['商品本體'],
          size: { label: '請選擇', value: '' },
          sizeOptions: [],
          supplier: { label: '請選擇', value: '' },
          supplierRepairNum: '',
          warrantyNumber: '',
        },
        onSubmit: () => {
          const params = {
            brandId: values.brand.value,
            color: values.color.value,
            model: values.model.label,
            modelId: values.model.value,
            otherRepiarItem: values.otherRepiarItem,
            productProblem: values.problem,
            productSerailNum: values.productSerailNum,
            purchaseDate: values.date
                ? moment(values.date).format('YYYY/MM/DD')
                : null,
            receiver: {
              address_receiver: values.receiver.address,
              cityId_receiver: values.receiver.city.value,
              countryCode_receiver: '886',
              countryId_receiver: values.receiver.country.value,
              email_receiver: values.receiver.email,
              firstName_receiver: values.receiver.firstName,
              lastName_receiver: values.receiver.lastName,
              phone_receiver: values.receiver.phoneNumber,
              townId_receiver: values.receiver.town.value,
            },
            repairItems: values.repairItems,
            size: values.size.value,
            supplier: {
              csType: '經銷商',
              supplierRepairNum: values.supplierRepairNum,
              supplier_receiver: values.receiverSupplier.value,
              supplier_receiverType: values.receiverType,
              supplier_sender: values.supplier.value,
            },
            supplierChannel: values.channel.label,
            supplierChannelId: values.channel.value,
            warrantySerailNum: values.warrantyNumber,
          };
          if (values.receiverType === '經銷商') {
            delete params.receiver;
          } else {
            delete params.supplier.supplier_receiver;
          }
          CommonUtil.consoleLog({ anno: 'request body', data: params });
          dispatch(addRepairRequest(2, params));
        },
        validationSchema: Yup.object().shape({
          brand: Yup.object().shape({
            label: Yup.string()
                .test('label', '商品品牌必填', value => value !== '請選擇')
                .required('商品品牌必填'),
          }),
          channel: Yup.object().shape({
            label: Yup.string()
                .test('label', '購買通路必填', value => value !== '請選擇')
                .required('購買通路必填'),
          }),
          color: Yup.object().when('colorOptions', {
            is: val => val.length > 0,
            then: Yup.object().shape({
              value: Yup.string().min(0).required('商品顏色必填'),
            }),
          }),
          colorOptions: Yup.array(),
          date: Yup.date().required('購買日期必填'),
          model: Yup.object().shape({
            label: Yup.string()
                .test('label', '商品型號必填', value => value !== '請選擇')
                .required('商品型號必填'),
          }),
          problem: Yup.string().min(0).required('商品問題必填'),
          receiverSupplier: Yup.object().when('receiverType', {
            is: val => val === '經銷商',
            then: Yup.object().shape({
              label: Yup.string()
                  .test('label', '收件經銷商必填', value => value !== '請選擇')
                  .required('收件經銷商必填'),
            }),
          }),
          receiverType: Yup.string().required('收件人類型必填'),
          repairItems: Yup.array()
              .min(1, '檢附項目必填')
              .required('檢附項目必填'),
          size: Yup.object().when('sizeOptions', {
            is: val => val.length > 0,
            then: Yup.object().shape({
              label: Yup.string()
                  .test('label', '商品尺寸必填', val => val !== '請選擇')
                  .required('必填'),
            }),
          }),
          sizeOptions: Yup.array(),
          supplier: Yup.object().shape({
            label: Yup.string()
                .test('label', '寄件經銷商必填', value => value !== '請選擇')
                .required('寄件經銷商必填'),
          }),
        }),
      });

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.maintainAdd);
  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const { repairBrandOptions } = useSelector(state => state.repairBrandOptions);
  const { repairModelOptions } = useSelector(state => state.repairModelOptions);
  const { repairColorOptions } = useSelector(state => state.repairColorOptions);
  const { sizeOptions } = useSelector(state => state.getSizeOptions);

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getCityList());
    dispatch(getAreaList());
    dispatch(getRepairBrandOption(0, 0));
    dispatch(getRepairModelOption(0, 0, ''));
    dispatch(getColorModelOption(0, 0, ''));
    dispatch(getRepairOptions());
    return () => {
      dispatch({ type: MAINTAIN_GET_INFO_RESET });
      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    };

  }, []);

  useEffect(() => {
    if (Number(values.brand.value) !== 0) {
      values.brand.value &&
      dispatch(getRepairModelOption(0, 0, values.brand.value));
    }
  }, [values.brand.value]);

  useEffect(() => {
    values.model.value &&
    dispatch(getColorModelOption(0, 0, values.model.value));
  }, [values.model.value]);

  useEffect(() => {
    dispatch(
        getSizeOptions({
          color: values.color.value,
          isRepair: 1,
          isWarranty: 0,
          modelId: values.model.value,
        }),
    );
  }, [values.model.value, values.color.value]);

  useEffect(() => {
    repairColorOptions?.length > 0
        ? setFieldValue('colorOptions', repairColorOptions)
        : setFieldValue('colorOptions', []);
  }, [repairColorOptions]);

  useEffect(() => {
    typeof sizeOptions === 'object' &&
    setFieldValue('sizeOptions', sizeOptions);
  }, [sizeOptions]);


  const {
    success: getSuccess,
    error: getError,
    info: productInfo,
  } = useSelector(state => state.getProductInfoViaWarrantySerialNumber);

  const getProductInfo = (value, type) => {
    value && dispatch(getProductInfoViaWarrantySerialNumber(value, type));
  };

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('儲存成功!!');
      dispatch({ type: MAINTAIN_ADD_RESET });
      history.goBack();
    }

    if (updateError) {
      MessageUtil.alertWanring('儲存失敗', updateError);
      dispatch({ type: MAINTAIN_ADD_RESET });
    }
  }, [updateSuccess, updateError]);

  const syncSenderInfo = () => {
    setFieldValue('receiverSupplier', values.supplier);
  };

  useEffect(() => {
    dispatch(getCityList(values?.receiver?.country?.value));
  }, [values?.receiver?.country?.value]);

  useEffect(() => {
    dispatch(getAreaList(values?.receiver?.city?.value));
  }, [values?.receiver?.city?.value]);

  const titleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
  };

  useEffect(() => {
    if (
        errors &&
        JSON.stringify(errors) !== '{}' &&
        touched &&
        JSON.stringify(touched) !== '{}' &&
        isSubmitting
    ) {
      let message = CommonUtil.getFormVaildationErrorMessages(errors);
      MessageUtil.alertErrorsWithHTML('資料請填寫完整', message);
    }
  }, [errors, touched, isSubmitting]);


  useEffect(() => {
    if (getSuccess && productInfo) {
      setFieldValue(
          'brand',
          repairBrandOptions?.filter(option => option.value == productInfo.brandId)[0],
      );
      setFieldValue('model', {
        label: productInfo.model,
        value: productInfo.modelId,
      });
      setFieldValue('color', {
        label: productInfo.color,
        value: productInfo.color,
      });
      setFieldValue('size', {
        label: productInfo.size,
        value: productInfo.size,
      });
      setFieldValue(
          'productSerailNum',
          productInfo.productSerialNum,
      );
      setFieldValue(
          'warrantyNumber',
          productInfo.warrantySerialNum,
      );
    }
  }, [getError, getSuccess , productInfo]);


  return (
      <Layout
          pageTitle="新增經銷商維修單"
          items={[
            { isActive: true, page_name: '維修清單', to: '/MaintainanceList' },
            { isActive: true, page_name: '新增經銷商維修單' },
          ]}
      >
        <Card className={style.card}>
          <div className={style.formGroup}>
            <span style={{ width: '10rem' }}>經銷商維修單號 </span>
            <Input
                type="text"
                onChange={event => {
                  setFieldValue('supplierRepairNum', event.target.value);
                }}
                value={values.supplierRepairNum}
            />
          </div>
          <h4 className="title mt-5" style={titleStyle}>
            寄件人資訊
          </h4>
          <div className={style.show_flex} style={{ marginBottom: '0.5rem' }}>
            <label style={{ width: '125px' }} className="required">
              請選擇經銷商
            </label>
            <Select
                name="sender_suppliers"
                className={style['form-select-long']}
                options={maintainOptions && maintainOptions.suppliers}
                onChange={option => {
                  setFieldValue('supplier', option);
                }}
                value={values.supplier}
            />
            {errors.supplier && touched.supplier && (
                <span className="text-danger ml-2">{errors.supplier.label}</span>
            )}
          </div>
          <h4 className="title mt-5" style={titleStyle}>
            收件人資訊
          </h4>
          <div className={style.show_flex} style={{ marginBottom: '0.5rem' }}>
            <div style={{ marginRight: '4rem' }}>
              <input
                  type="radio"
                  checked={values.receiverType === '經銷商'}
                  onChange={event => {
                    setFieldValue('receiverType', event.target.value);
                  }}
                  value="經銷商"
              />
              <span style={{ marginLeft: '0.5rem' }}>經銷商</span>
            </div>
            <div>
              <input
                  type="radio"
                  checked={values.receiverType === '客戶'}
                  onChange={event => {
                    setFieldValue('receiverType', event.target.value);
                  }}
                  value="客戶"
              />
              <span style={{ marginLeft: '0.5rem' }}>客戶</span>
            </div>
          </div>
          {values.receiverType === '經銷商' && (
              <div className={`mb-3 ${style.show_flex}`}>
                <label style={{ width: '125px' }} className="required">
                  請選擇經銷商
                </label>
                <Select
                    name="sender_suppliers"
                    className={style['form-select-long']}
                    options={maintainOptions && maintainOptions.suppliers}
                    onChange={option => {
                      setFieldValue('receiverSupplier', option);
                    }}
                    value={values.receiverSupplier}
                />
                {errors.receiverSupplier && touched.receiverSupplier && (
                    <span className="text-danger ml-2">
                {errors.receiverSupplier.label}
              </span>
                )}
                <div className="w100" />
                <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'left',
                      marginTop: '0.5rem',
                    }}
                >
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked && syncSenderInfo();
                      }}
                  />
                  <span style={{ marginLeft: '1rem' }}>同寄件經銷商</span>
                </div>
              </div>
          )}
          {values.receiverType === '客戶' && (
              <div style={{ width: '100%' }}>
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className={'w100'}>姓氏</label>
                  <Input
                      className={style['first-name-input']}
                      onChange={event => {
                        setFieldValue('receiver.lastName', event.target.value);
                      }}
                      value={values.receiver.lastName}
                      invalid={
                          errors.receiver?.lastName && touched.receiver?.lastName
                      }
                  />
                  {errors.receiver && touched.receiver && (
                      <span className="text-danger ml-2">
                  {errors.receiver.lastName}
                </span>
                  )}
                  <div style={{ width: '100px' }} />
                  <label className={'w100'}>名字</label>
                  <Input
                      className={style['last-name-input']}
                      onChange={event => {
                        setFieldValue('receiver.firstName', event.target.value);
                      }}
                      value={values.receiver.firstName}
                      invalid={
                          errors.receiver?.firstName && touched.receiver?.firstName
                      }
                  />
                  {errors.receiver && touched.receiver && (
                      <span className="text-danger ml-2">
                  {errors.receiver.firstName}
                </span>
                  )}
                </div>
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w100">手機號碼</label>
                  <Input
                      className={style['large-input']}
                      onChange={event => {
                        setFieldValue('receiver.phoneNumber', event.target.value);
                      }}
                      value={values.receiver.phoneNumber}
                      invalid={
                          errors.receiver?.phoneNumber && touched.receiver?.phoneNumber
                      }
                  />
                  {errors.receiver && touched.receiver && (
                      <span className="text-danger ml-2">
                  {errors.receiver.phoneNumber}
                </span>
                  )}
                </div>
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w100">電子信箱</label>
                  <Input
                      className={style['large-input']}
                      onChange={event => {
                        setFieldValue('receiver.email', event.target.value);
                      }}
                      value={values.receiver.email}
                      invalid={errors.receiver?.email && touched.receiver?.email}
                  />
                  {errors.receiver && touched.receiver && (
                      <span className="text-danger ml-2">
                  {errors.receiver.email}
                </span>
                  )}
                </div>
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w100">國家/地區</label>
                  <Select
                      className={style['form-select-short']}
                      style={{ margin: 0 }}
                      options={[{ label: '請選擇', value: 0 }, ...countryOptions]}
                      onChange={({ label, value }) => {
                        setFieldValue('receiver.country', { label, value });
                        setFieldValue('receiver.city', { label: '請選擇', value: 0 });
                      }}
                      value={values?.receiver?.country}
                  />
                  {errors.receiver?.country && touched.receiver?.country && (
                      <span className="text-danger ml-2">
                  {errors.receiver.country.label}
                </span>
                  )}
                  <div className="w50" />
                  <label style={{ marginRight: '0.5rem' }}>城市</label>
                  <Select
                      className={style['form-select-short']}
                      style={{ margin: 0 }}
                      options={[{ label: '請選擇', value: 0 }, ...cityListOptions]}
                      onChange={({ label, value }) => {
                        setFieldValue('receiver.city', { label, value });
                        setFieldValue('receiver.town', { label: '請選擇', value: 0 });
                      }}
                      value={values.receiver?.city}
                  />
                  {errors.receiver?.city && touched.receiver?.city && (
                      <span className="text-danger ml-2">
                  {errors.receiver.city.label}
                </span>
                  )}
                  <div className="w100" />
                  <label style={{ marginRight: '0.5rem' }}>區域</label>
                  <Select
                      className={style['form-select-short']}
                      style={{ margin: 0 }}
                      options={[{ label: '請選擇', value: 0 }, ...areaListOptions]}
                      onChange={({ label, value }) => {
                        setFieldValue('receiver.town', { label, value });
                      }}
                      value={values.receiver?.town}
                  />
                  {errors.receiver?.town && touched.receiver?.town && (
                      <span className="text-danger ml-2">
                  {errors.receiver.town.label}
                </span>
                  )}
                </div>
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w100">詳細地址</label>
                  <Input
                      className={style['large-input']}
                      onChange={event => {
                        setFieldValue('receiver.address', event.target.value);
                      }}
                      value={values.receiver?.address}
                      invalid={errors.receiver?.address && touched.receiver?.address}
                  />
                  {errors.receiver?.address && touched.receiver?.address && (
                      <span className="text-danger ml-2">
                  {errors.receiver.address}
                </span>
                  )}
                </div>
              </div>
          )}
          {/**************  維修品資訊 **************/}
          <h4 className="title mt-5" style={titleStyle}>
            維修品資訊
          </h4>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="required w100">商品品牌</label>
            <Select
                name="brandId"
                className={style['form-select']}
                style={{ maring: 0 }}
                options={
                    repairBrandOptions && [
                      { label: '請選擇', value: '' },
                      ...repairBrandOptions,
                    ]
                }
                onChange={option => {
                  setFieldValue('brand', option);
                  setFieldValue('model', { label: '請選擇', value: '' });
                  setFieldValue('color', { label: '請選擇', value: '' });
                  setFieldValue('size', { label: '請選擇', value: '' });
                }}
                value={values.brand}
            />
            {errors.brand && touched.brand && (
                <span className="text-danger ml-2">{errors.brand.label}</span>
            )}
          </div>
          {repairModelOptions?.length > 0 && (
              <div className={`mb-3 ${style.show_flex}`}>
                <label className="required w100">商品型號</label>
                <Select
                    name="model"
                    className={style['form-select']}
                    style={{ maring: 0 }}
                    options={
                        repairModelOptions && [
                          { label: '請選擇', value: '' },
                          ...repairModelOptions,
                        ]
                    }
                    onChange={option => {
                      setFieldValue('model', option);
                      setFieldValue('color', { label: '請選擇', value: '' });
                      setFieldValue('size', { label: '請選擇', value: '' });
                    }}
                    value={values.model}
                />
                {errors.model && touched.model && (
                    <span className="text-danger ml-2">{errors.model.label}</span>
                )}
              </div>
          )}
          {repairColorOptions?.length > 0 && (
              <div className={`mb-3 ${style.show_flex}`}>
                <label className="w100 required">商品顏色</label>
                <Select
                    name="color"
                    className={style['form-select']}
                    style={{ maring: 0 }}
                    options={
                      repairColorOptions && repairColorOptions.length > 0
                          ? repairColorOptions
                          : maintainOptions && maintainOptions.colors
                    }
                    onChange={option => {
                      setFieldValue('color', option);
                      setFieldValue('size', { label: '請選擇', value: '' });
                    }}
                    value={values.color}
                />
                {errors.color && touched.color && (
                    <span className="text-danger ml-2">{errors.color.value}</span>
                )}
              </div>
          )}
          {sizeOptions?.length > 0 && (
              <div className={`${style.show_flex} mb-3`}>
                <label className="required w100">商品尺寸</label>
                <Select
                    className={style['form-select']}
                    options={sizeOptions || []}
                    value={values.size}
                    onChange={option => {
                      setFieldValue('size', option);
                    }}
                />
                {errors.size && touched.size && (
                    <span className="text-danger ml-2">{errors.size.label}</span>
                )}
              </div>
          )}
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w100">商品序號</label>
            <Input
                className={style['serial-number-input']}
                value={values.productSerailNum}
                onChange={event => {
                  setFieldValue('productSerailNum', event.target.value);
                }}
                onBlur={e => {
                  getProductInfo(e.target.value, 'productSerial');
                }}
            />
          </div>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w100">保固序號</label>
            <Input
                className={style['serial-number-input']}
                value={values.warrantyNumber}
                onChange={event => {
                  setFieldValue('warrantyNumber', event.target.value);
                }}
                onBlur={e => {
                  getProductInfo(e.target.value, 'warrantSerial');
                }}
            />
          </div>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w100 required">購買日期</label>
            <div className={style['form-select']}>
              <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={values.date}
                  maxDate={moment('9999-12-31').toDate()}
                  onChange={date => {
                    setFieldValue('date', date);
                  }}
              />
            </div>
            {errors.date && touched.date && (
                <span className="text-danger ml-2">{errors.date}</span>
            )}
          </div>
          <div className={style.show_flex}>
            <label className="required w100">購買通路</label>
            <Select
                name="channel"
                className={style['form-select-long']}
                options={maintainOptions && maintainOptions.channels}
                onChange={option => {
                  setFieldValue('channel', option);
                }}
                value={values.channel}
            />
            {errors.channel && touched.channel && (
                <span className="text-danger ml-2">{errors.channel.label}</span>
            )}
          </div>
          <h4 className="title mt-5 required" style={titleStyle}>
            檢附品項
            {errors.repairItems && touched.repairItems && (
                <span className="text-danger ml-2 fw-normal">
              {errors.repairItems}
            </span>
            )}
          </h4>
          <div>
            <div className={style.repairItem_group}>
              <div className={style.show_flex}>
                {maintainOptions &&
                    maintainOptions.repairItems.map((item, index) => (
                        <div
                            key={index}
                            style={{
                              alignContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                            }}
                        >
                          <input
                              type="checkbox"
                              style={{ height: '20px', width: '20px' }}

                              // disabled={item.value === '商品本體'}
                              checked={values.repairItems.includes(item.value)}
                              onChange={event => {
                                const checked = event.target.checked;
                                checked
                                    ? !values.repairItems.includes(item.value) &&
                                    setFieldValue('repairItems', [
                                      ...values.repairItems,
                                      item.value,
                                    ])
                                    : setFieldValue(
                                        'repairItems',
                                        values.repairItems.filter(i => i !== item.value),
                                    );
                              }}
                          />
                          <span style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
                      {item.value}
                    </span>
                        </div>
                    ))}
                <div
                    style={{
                      alignContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                >
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}

                      // checked={values.otherRepiarItem?.trim() !== "" && values.otherRepiarItem !== null}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked
                            ? setFieldValue(
                                'otherRepiarItem',
                                values.otherRepiarItemInputValue,
                            )
                            : setFieldValue('otherRepiarItem', '');
                      }}
                  />
                  <span
                      style={{
                        marginLeft: '0.5rem',
                        marginRight: '1rem',
                        whiteSpace: 'nowrap',
                      }}
                  >
                  其他
                </span>
                  <Input
                      className={style['form-input']}
                      onChange={event => {
                        setFieldValue(
                            'otherRepiarItemInputValue',
                            event.target.value,
                        );
                      }}
                      value={values.otherRepiarItemInputValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <h4 className="title mt-8 required" style={titleStyle}>
            商品問題
          </h4>
          {errors.problem && touched.problem && (
              <span className="text-danger ml-2">{errors.problem}</span>
          )}
          <Input
              type="textarea"
              style={{ height: '180px' }}
              value={values.problem}
              onChange={event => {
                setFieldValue('problem', event.target.value);
              }}
              invalid={errors.problem && touched.problem}
          />
          <div className="d-flex justify-content-center mt-5">
            <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => {
                  history.goBack()
                }}
            >
              取消
            </Button>
            <Button
                type="submit"
                className={style.modal_button_submit}
                disabled={updateLoading}
                onClick={handleSubmit}
            >
              {updateLoading && (
                  <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  ></span>
              )}
              <span className="btn-wrapper--label">儲存</span>
            </Button>
          </div>
        </Card>
      </Layout>
  );
};

export default MaintainanceAdd;
